import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Link, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import TextField from "../components/molecules/TextField";
import AuthTemplate from "../components/templates/AuthTemplate";
import { register } from "../services/auth";
import useApp from "../hooks/queries/useApp";

const schema = yup
  .object({
    email: yup.string().email("E-mail inválido").required("Digite seu e-mail"),
    password: yup
      .string()
      .min(6, "A senha deve conter 6 ou mais caracteres")
      .required("Digite sua senha"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "As senhas não conferem"),
    name: yup.string().required("Digite seu nome"),
  })
  .required();

const Register = () => {
  const { app } = useApp();

  let navigate = useNavigate();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailIsUsed, setEmailIsUsed] = useState(false);
  const [password, setPassword] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
    },
  });
  const onSubmit = (data) => {
    setIsSubmitted(true);
    register(app?._id, data.email, data.password, data.name)
      .then((res) => {
        if (res && res.data) {
          navigate("/login");
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.email == "The email has already been taken."
        ) {
          setEmailIsUsed(true);
          setError("email", "manual", "E-mail já utilizado");
        }
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };
  return (
    <AuthTemplate>
      <Box
        component="form"
        sx={{ textAlign: "center" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h7" color="primary">
            Criar conta
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            name="name"
            control={control}
            label="Nome"
            placeholder="Digite seu nome"
            error={errors.name}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            name="email"
            control={control}
            label="E-mail"
            placeholder="Digite seu e-mail"
            error={errors.email}
            onChange={(e) => {
              setEmailIsUsed(false);
              clearErrors("email", { manual: false });
              setValue("email", e.target.value.toLowerCase().trim());
            }}
          />
          {emailIsUsed && (
            <Box sx={{ width: "100%", textAlign: "left", paddingLeft: 2 }}>
              <Typography
                variant="caption"
                sx={{ color: "#d32f2f", fontSize: 12 }}
              >
                Email já utilizado
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          <TextField
            type="password"
            name="password"
            control={control}
            label="Senha"
            placeholder="Digite sua senha"
            error={errors.password}
            onChange={(e) => {
              setValue("password", e.target.value);
              setPassword(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <PasswordStrengthBar
            password={password}
            minLength={6}
            scoreWords={["fraco", "fraco", "razoável", "bom", "muito bom"]}
            shortScoreWord="curto demais"
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            type="password"
            name="confirmPassword"
            control={control}
            label="Confirmar senha"
            placeholder="Digite sua senha novamente"
            error={errors.confirmPassword}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Button type="submit" sx={{ width: "100%" }} disabled={isSubmitted}>
            Criar conta
          </Button>
        </Box>

        <Box
          sx={{
            mb: 2,
            display: " flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            to="/login"
            underline="none"
            sx={{ fontWeight: 500 }}
            component={RouterLink}
            color="primary"
          >
            Já tenho uma conta
          </Link>
        </Box>
      </Box>
    </AuthTemplate>
  );
};

export default Register;
