import React from "react";
import { useSearchParams } from "react-router-dom";
import { AUTH_DOMAIN_NAME, AUTH_REDIRECT_URL } from "../constants/auth";

const Logout = () => {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectUrl");

  localStorage.removeItem(AUTH_DOMAIN_NAME);
  localStorage.removeItem(AUTH_REDIRECT_URL);

  if (redirectUrl) {
    window.location.href = `http://${redirectUrl}`;
  }
  
  return <></>;
};

export default Logout;
