import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeTemplate } from "./components/templates/ThemeTemplate";
import { AppRouter, AppRouterUnknown } from "./Routes/AppRouter";
import "./styles/global.css";
import { AUTH_DOMAIN_NAME } from "./constants/auth";

function App() {
  const domainName = localStorage.getItem(AUTH_DOMAIN_NAME);

  return (
    <div className="App">
      <CssBaseline />
      <ThemeTemplate>
        {domainName ? <AppRouter /> : <AppRouterUnknown />}
      </ThemeTemplate>

      <ToastContainer />
    </div>
  );
}

export default App;
