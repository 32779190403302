import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import useApp from "../../../hooks/queries/useApp";

const AuthTemplate = ({ children }) => {
  const { app } = useApp();

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
          },
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="xs">
          <Card>
            <CardContent>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 32,
                  my: 2,
                  fontWeight: "700",
                }}
                color={"primary"}
              >
                {app?.name}
              </Typography>
              <Box>{children}</Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Box>
  );
};

AuthTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthTemplate;
