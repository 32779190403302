import { Route, Routes } from "react-router-dom";
import Entry from "../pages/Entry";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import NewPassword from "../pages/NewPassword";
import RecoverPassword from "../pages/RecoverPassword";
import Register from "../pages/Register";
import Page404 from "../pages/Page404";

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" exact element={<Entry />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="recover-password" element={<RecoverPassword />} />
      <Route path="new-password/:hash" element={<NewPassword />} />
      <Route path="logout" element={<Logout />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export function AppRouterUnknown() {
  return (
    <Routes>
      <Route path="/" element={<Entry />} />
      <Route path="logout" element={<Logout />} />
    </Routes>
  );
}
