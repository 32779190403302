import { Container } from "@mui/material";

const AppTemplate = (props) => {
  const { children,  ...rest } = props;


  return (
    <>
      <Container sx={{ marginTop: 2 }} {...rest}>
        {children}
      </Container>

    </>
  );
};

export default AppTemplate;
