import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Link, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import TextField from "../components/molecules/TextField";
import AuthTemplate from "../components/templates/AuthTemplate";
import { recoverPassword } from "../services/auth";

const schema = yup
  .object({
    email: yup.string().email("E-mail inválido").required("Digite seu e-mail"),
  })
  .required();

const RecoverPassword = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });
  const onSubmit = (data) => {
    setIsSubmitted(true);
    recoverPassword(data.email)
      .then((res) => {
        if (res && res.status === 200) {
          setIsSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };
  return (
    <AuthTemplate>
      {isSuccess ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" color="textPrimary">
            Enviamos um e-mail para você com instruções para recuperar sua
            senha.
          </Typography>
          <Typography variant="div" color="textPrimary" sx={{ marginTop: 2 }}>
            Caso não tenha recebido, verifique sua caixa de spam.
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Link
              to="/login"
              underline="none"
              sx={{ fontWeight: 500 }}
              component={RouterLink}
            >
              Voltar ao login
            </Link>
          </Box>
        </Box>
      ) : (
        <Box
          component="form"
          sx={{ textAlign: "center" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="h7" color="primary">
              Recuperar senha
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="email"
              control={control}
              label="E-mail"
              placeholder="Digite seu e-mail"
              error={errors.email}
              onChange={(e) => {
                setValue("email", e.target.value.toLowerCase().trim());
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button type="submit" sx={{ width: "100%" }} disabled={isSubmitted}>
              Enviar
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Link
              to="/login"
              underline="none"
              sx={{ fontWeight: 500 }}
              component={RouterLink}
            >
              Voltar ao login
            </Link>
          </Box>
        </Box>
      )}
    </AuthTemplate>
  );
};

export default RecoverPassword;
