import { Card, ThemeProvider, createTheme } from "@mui/material";
import useApp from "../../../hooks/queries/useApp";

const appTheme = (style) => {
  if (!style) return createTheme({});

  return createTheme({
    palette: {
      mode: style?.type,

      primary: {
        main: style?.primaryColor,
      },
      secondary: {
        main: style?.secondaryColor,
      },
      background: {
        default: style?.backgroundColor,
      },
      text: {
        primary: style?.textColor,
      },
    },
  });
};

const ThemeTemplate = ({ children }) => {
  const { isLoading, styles } = useApp();
  
  if (isLoading && !styles) return <>Carregando...</>;

  const style = styles[0];

  return (
    <ThemeProvider theme={appTheme(style)}>
      <Card
        sx={{
          borderRadius: 0,
          backgroundColor: style?.backgroundColor,
          minHeight: "100vh",
        }}
      >
        {/* {styles.map((style) => (
          <button key={style.type} onClick={() => setCurrentStyle(style.type)}>
            {style.type}
          </button>
        ))} */}
        {children}
      </Card>
    </ThemeProvider>
  );
};

export default ThemeTemplate;
