import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  AUTH_DOMAIN_NAME,
  AUTH_REDIRECT_URL,
  AUTH_THEME,
} from "../constants/auth";

const Entry = () => {
  const [searchParams] = useSearchParams();

  const domainName = searchParams.get("domainName");
  const redirectUrl = searchParams.get("redirectUrl");
  const entry = searchParams.get("entry");
  const theme = searchParams.get("theme") || "light";

  useEffect(() => {
    if (domainName && redirectUrl && entry) {
      localStorage.setItem(AUTH_DOMAIN_NAME, domainName);
      localStorage.setItem(AUTH_REDIRECT_URL, redirectUrl);
      window.location.href = "/" + entry;
    }

    if (theme) {
      localStorage.setItem(AUTH_THEME, theme);
    }
  }, [domainName, redirectUrl, theme, entry]);

  if (!domainName || !redirectUrl) {
    return (
      <Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              height: 100,
            }}
          >
            App não identificado
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {domainName} {redirectUrl}
    </>
  );
};

export default Entry;
